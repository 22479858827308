// Styles
import './Display_5.css';

// Icons
import PlaceholderImage1 from './icons/fast-delivery.svg';
import PlaceholderImage2 from './icons/pickup-truck.svg';
import PlaceholderImage3 from './icons/size.svg';
import PlaceholderImage4 from './icons/mosque.svg';
import { useEffect } from 'react';

const Display_5 = () => {
    useEffect(() => console.log(document.getElementsByClassName("display-5-inner-container")[0].offsetWidth), []);

    return (
        <div className='display-5-container'>
            <div className='display-5-container-background-filler'></div>
            <div className='display-5-inner-container'>
                <p className='display-5-title-context'>515-418-8265</p>
                <p className='display-5-title'>Dumpster Rental in Central Iowa</p>
                <div className='display-5-statement-container'>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage1}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Same Day Delivery</p>
                        <p className='display-5-statement-statement'>
                            We proudly offer <span className='display-5-statement-statement-highlight'>same-day dumpster delivery services</span> to meet your most urgent requirements. Whether you’re taking on a major home renovation, a community cleanup, or a last-minute event, our commitment is to ensure you have a dumpster right when and where you need it.
                        </p>
                    </div>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage2}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Same Day Pick Up</p>
                        <p className='display-5-statement-statement'>
                           We offer <span className='display-5-statement-statement-highlight'>same-day dumpster pickup services</span> tailored for those who seek an efficient and timely pickup. Whether you've wrapped up a home renovation faster than anticipated, finished a community event, or simply want to clear the site promptly, we're here to ensure a hassle-free experience.
                        </p>
                    </div>
                    <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage3}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Different Dumpster Sizes for Specific Needs</p>
                        <p className='display-5-statement-statement'>
                            We offer <span className='display-5-statement-statement-highlight'>20 and 30 yard dumpster solutions</span> tailored to your disposal needs. Whether you're working on a minor home renovation or a major construction site, we've got you covered.
                        </p>
                    </div>
                    {/* <div className='display-5-statement'>
                        <img
                            className='display-5-statement-icon'
                            src={PlaceholderImage4}
                            alt='Display 5 Statement Icon'
                        />
                        <p className='display-5-statement-title'>Statement Title</p>
                        <p className='display-5-statement-statement'>
                            In hac habitasse <span className='display-5-statement-statement-highlight'>platea dictumst. </span> 
                            Praesent congue magna euismod neque maximus, ac consequat ipsum eleifend. Donec imperdiet suscipit
                            purus eget gravida. Vestibulum tempus nulla sit.
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Display_5;
