// Styles
import './Display_8.css';

// Icons
// import PlaceholderIcon from '../../../location.svg';
import PlaceholderIcon1 from './icons/remodeling.svg';
import PlaceholderIcon2 from './icons/skyline.svg';
import PlaceholderIcon3 from './icons/house.svg';


import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Display_8 = () => {
    useEffect(() => {
        console.log(document.getElementsByClassName("display2-end-statement")[0].offsetWidth);
    },);

    return (
        <div className='display2-container'>
            <p className='display2-title'>Pricing Details</p>
            <div className='display2-cards-container'>
                <div className='display2-cards-card'>
                    {/* <img
                        className='display2-card-icon'
                        src={PlaceholderIcon1}
                        alt='Display Card Icon'
                    /> */}
                    <p className='display2-card-title'>10 Yard Dumpster<br />$260</p>
                    <p className='display2-card-statement'>Ideal for a small remodel or removal of several large pieces of junk. Our 10 yard dumpster provides enough space for you to remove just what you need.</p>
                </div>
                <div className='display2-cards-card'>
                    {/* <img
                        className='display2-card-icon'
                        src={PlaceholderIcon2}
                        alt='Display Card Icon'
                    /> */}
                    <p className='display2-card-title'>20 Yard Dumpster<br />$295</p>
                    <p className='display2-card-statement'>Our most popular rental is the 20 yard dumpster. Ideal for large remodels and even construction job sites, this dumpster size is versatile and provides everything you ned to clean up your space.</p>
                </div>
                <div className='display2-cards-card'>
                    {/* <img
                        className='display2-card-icon'
                        src={PlaceholderIcon3}
                        alt='Display Card Icon'
                    /> */}
                    <p className='display2-card-title'>30 Yard Dumpster<br />$375</p>
                    <p className='display2-card-statement'>Perfect for construction sites. This dumpster size is made for the construction job site and is used by contractors and builders throughout Iowa. Get rid of any construciton material with this dumpster.</p>
                </div>
            </div>
            <p className='display2-end-statement'>
                <b className='display2-phone-number'>515-418-8265</b><br />
                <b className='display2-email'>DumpsterTroopers@gmail.com</b>
            </p>
            <div className='display2-btn-container'>
                <Link to="/contact" className='display2-btn'>Rent a Dumpster</Link>
                {/* <button className='display-btn'>Button</button> */}
            </div>
        </div>
    );
};

export default Display_8;
