// COMPONENTS
import Menu_1 from "../../components/menu_1/menu_1";
import Display_1 from "../../components/Display_1/Display_1";
import Display_2 from "../../components/Display_2/Display_2"
import Display_3 from "../../components/Display_3/Display_3"
import Display_4 from "../../components/Display_4/Display_4"
import Display_5 from "../../components/Display_5/Display_5"
import Display_6 from "../../components/Display_6/Display_6"
import Display_7 from "../../components/Display_7/Display_7"
import Display_8 from "../../components/Display_8/Display_8";

// STYLES
import "./Home.css";

// IMAGES
import HeroImage from "../../images/dumpster_image_long.jpg";
import DumpsterTroopersLOGO from "../../images/Dumpster Troopers LOGO.svg";

// CORE
import { Link } from "react-router-dom";

const Home = () => {
    return (
        <>
            <Menu_1 />
            <div className="home-page-hero-outer-container">
                {/* <div className="home-page-hero-background-filler"></div> */}
                <div className="home-page-hero-title-title-statement-container">
                    {/* <h1 className="home-page-hero-title">Dumpster Troopers</h1> */}
                    <img className="home-page-hero-title-image" src={DumpsterTroopersLOGO} />
                    <h2 className="home-page-hero-title-statement">Roll off dumpster rental in Central Iowa.</h2>
                    <div className="home-page-hero-btn-container">
                       <Link className="home-page-hero-rent-a-dumpster-btn" to="/rent-a-dumpster">Get a Quote</Link>
                       <Link className="home-page-hero-contact-us-btn" to="/contact">Contact Us</Link>
                    </div>
                </div>
                <img className="home-page-hero-image" src={HeroImage} />
            </div>
            <Display_5 />
            <Display_8 />
            <Display_2 />
            <Display_7 />
        </>
    );
};

export default Home;
