// STYLES
import "./RentADumpster.css";

// COMPONENTS
import Menu_1 from "../../components/menu_1/menu_1";
import Display_2 from "../../components/Display_2/Display_2";
import Display_8 from "../../components/Display_8/Display_8";

const RentADumpster = () => {
    return (
        <div className="rent-a-dumpster-outer-container">
            <Menu_1 />
            <Display_8 />
            <Display_2 />
        </div>
    );
};

export default RentADumpster;
