// STYLES
import "./Contact.css";

// COMPONENTS
import Menu_1 from "../../components/menu_1/menu_1";

const Contact = () => {
    return (
        <div className="rent-a-dumpster-outer-container">
            <Menu_1 />
            <div className="contact-outer-container">
                <h1 className="contact-title">Contact us today!</h1>
                <p className="contact-phone-number">515-418-8265</p>
                <p className="contact-email">DumpsterTroopers@gmail.com</p>
            </div>
        </div>
    );
};

export default Contact;
