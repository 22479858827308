// STYLES
import "./FAQ.css";

// COMPONENTS
import Menu_1 from "../../components/menu_1/menu_1";
import Display_7 from "../../components/Display_7/Display_7";

const FAQ = () => {
    return (
        <div className="rent-a-dumpster-outer-container">
            <Menu_1 />
            <Display_7 />
        </div>
    );
};

export default FAQ;
