// Styles
import './Display_2.css';

// Icons
// import PlaceholderIcon from '../../../location.svg';
import PlaceholderIcon1 from './icons/remodeling.svg';
import PlaceholderIcon2 from './icons/skyline.svg';
import PlaceholderIcon3 from './icons/house.svg';


import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Display_2 = () => {
    useEffect(() => {
        console.log(document.getElementsByClassName("display2-end-statement")[0].offsetWidth);
    },);

    return (
        <div className='display2-container'>
            <p className='display2-title'>Dumpsters for Your Project</p>
            <div className='display2-cards-container'>
                <div className='display2-cards-card'>
                    <img
                        className='display2-card-icon'
                        src={PlaceholderIcon1}
                        alt='Display Card Icon'
                    />
                    <p className='display2-card-title'>Residential</p>
                    <p className='display2-card-statement'>During remodeling projects, debris accumulates rapidly. Renting a dumpster provides a centralized disposal solution, ensuring a safe, organized, and efficient work site. It's not just about waste—it's about streamlining workflow and maximizing productivity.</p>
                </div>
                <div className='display2-cards-card'>
                    <img
                        className='display2-card-icon'
                        src={PlaceholderIcon2}
                        alt='Display Card Icon'
                    />
                    <p className='display2-card-title'>Commercial</p>
                    <p className='display2-card-statement'>For commercial projects, waste management is paramount. Dumpster rentals offer an organized way to handle large-scale debris, ensuring a safe and efficient job site. It's a crucial step towards project success and timely completion.</p>
                </div>
                <div className='display2-cards-card'>
                    <img
                        className='display2-card-icon'
                        src={PlaceholderIcon3}
                        alt='Display Card Icon'
                    />
                    <p className='display2-card-title'>New Construction</p>
                    <p className='display2-card-statement'>New construction generates significant debris. Dumpster rentals provide an essential solution for efficient waste management, ensuring a clean and streamlined building site. It's a foundational step for a smooth construction process.</p>
                </div>
            </div>
            <p className='display2-end-statement'>
                <b>515-418-8265</b><br />
                <b>DumpsterTroopers@gmail.com</b>
            </p>
            <div className='display2-btn-container'>
                <Link to="/contact" className='display2-btn'>Rent a Dumpster</Link>
                {/* <button className='display-btn'>Button</button> */}
            </div>
        </div>
    );
};

export default Display_2;
