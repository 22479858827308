// Styles
import './menu_1.css';

// Icons
import MenuIcon from './icons/Dumpster Troopers LOGO.svg';
import MobileMenuHamburgerIcon from './icons/more.svg';
import MobileMenuExitIcon from './icons/cancel.svg';

// Core
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const Menu_1 = () => {
    const mobileMenuLinks = useRef(null);
    const mobileMenuHamburgerIcon = useRef(null);
    const mobileMenuExitIcon = useRef(null);

    // Event Listeners
    const openMobileMenu = () => {
        mobileMenuLinks.current.style.display = 'block';
    };

    const exitMobileMenu = () => {
        mobileMenuLinks.current.style.display = 'none';
    };

    return (
        <nav className='menu-1-outer-container'>
            <div className='menu-1-inner-container'>
                <div className='menu-1-mobile-menu-container'>
                    <Link to="/">
                        <img
                            className='menu-1-mobile-menu-logo'
                            src={MenuIcon}
                            alt='Mobile Menu Icon'
                        />
                    </Link>
                    <img
                        className='menu-1-mobile-menu-hamburger'
                        src={MobileMenuHamburgerIcon}
                        alt='Mobile Menu Hamburger Icon'
                        ref={mobileMenuHamburgerIcon}
                        onClick={openMobileMenu}
                    />
                    <div className='mobile-menu-links-container' ref={mobileMenuLinks}>
                        <img
                            className='mobile-menu-exit-icon'
                            src={MobileMenuExitIcon}
                            alt='Mobile Menu Exit Icon'
                            ref={mobileMenuExitIcon}
                            onClick={exitMobileMenu}
                        />
                        <ul className='mobile-menu-links'>
                            <Link to="/frequently-asked-questions" className='mobile-menu-link'>Frequently Asked Questions</Link>
                            <Link to="/rent-a-dumpster" className='mobile-menu-link'>Rent a Dumpster</Link>
                            <Link to="/contact" className='mobile-menu-link'>Contact</Link>
                        </ul>
                    </div>
                </div>
                <div className='menu-1-links-container'>
                    <Link to="/">
                        <img
                            className='menu-1-menu-logo'
                            src={MenuIcon}
                            alt='Mobile Menu Icon'
                        />
                    </Link>
                    <ul className='menu-1-links'>
                        <Link to="/" className='menu-1-link'>
                            <img
                                className='menu-1-menu-desktop-logo'
                                src={MenuIcon}
                                alt='Desktopo View Dumpster Troopers Logo'
                            />
                        </Link>
                        <Link to="/frequently-asked-questions" className='menu-1-link'>Frequently Asked Questions</Link>
                        <Link to="/rent-a-dumpster" className='menu-1-link'>Rent a Dumpster</Link>
                        <Link to="/contact" className='menu-1-link'>Contact</Link>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Menu_1;
