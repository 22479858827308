import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

// PAGES
import HomePAGE from './frontend/pages/Home/Home';
import RentADumpsterPAGE from './frontend/pages/RentADumpster/RentADumpster';
import Contact from './frontend/pages/Contact/Contact';
import FAQ from './frontend/pages/FAQ/FAQ';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePAGE />,
    // loader: rootLoader,
    // children: [
    //   {
    //     path: "test",
    //     element: <RentADumpsterPAGE />,
    //     // loader: teamLoader,
    //   },
    // ],
  },
  {
    path: "/frequently-asked-questions",
    element: <FAQ />
  },
  {
    path: "/rent-a-dumpster",
    element: <RentADumpsterPAGE />
  },
  {
    path: "/contact",
    element: <Contact />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
